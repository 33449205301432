import React from "react"

import Layout from "../page-components/layout"
import SEO from "../page-components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container">
      <div className="row">
        <div className="col-12 py-5">

            <h1>404 - Seite konnte nicht gefunden werden</h1>
            <p>Die von Ihnen angeforderte Seite kann leider nicht gefunden werden.</p>

          <br />
          <Link to={"/"}>Zur Startseite</Link>
          <br />
          <br />

        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
